import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {QT_LOGOS} from 'configs';
import {Image} from 'components/Images';

class ImageQTLogo extends React.Component {
  static propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    inverse: PropTypes.bool,
    block: PropTypes.bool,
    tagLine: PropTypes.bool,
  };

  static defaultProps = {
    size: 'md',
    inverse: false,
    tagLine: false,
  };

  get src() {
    const {inverse, tagLine} = this.props;

    return inverse
      ? tagLine
        ? QT_LOGOS.lightTag
        : QT_LOGOS.light
      : tagLine
      ? QT_LOGOS.defaultTag
      : QT_LOGOS.default;
  }

  get className() {
    const {size, block, className} = this.props;

    return classNames(
      styles['image-logo-qt'],
      {
        [styles[`image-logo-qt--${size}`]]: size,
        [styles['image-logo-qt--block']]: block,
      },
      className
    );
  }

  render() {
    return (
      <Image className={this.className} src={this.src} alt="QualityTrade" />
    );
  }
}

export default ImageQTLogo;
