import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';
import {
  SIZES,
  // IAF_LOGOS,
} from 'configs';

import {Image} from 'components/Images';

const Component = ({size, ...rest}) => {
  return (
    <Image
      className={classNames(styles['image-logo'], {
        [styles[`image-logo--${size}`]]: size,
      })}
      // src={IAF_LOGOS[size]}
      src="https://img.iafcertsearch.org/r/iaf-logo-w256.png"
      alt="IAF Logo"
      {...rest}
    />
  );
};

Component.defaultProps = {
  size: SIZES.md,
};

export default Component;
