export const ACTIVITY_STATUS = {
  success: 'success',
  error: 'error',
  uploading: 'uploading',
  downloading: 'downloading',
  downloaded: 'downloaded',
  skipped: 'skipped',
  finished: 'finished',
};

export const ACTIVITY_TYPE = {
  file_upload: 'file_upload',
  ftp: 'ftp_upload',
  api: 'api',
};

export const ACTIVITY_TYPE_LABEL = {
  [ACTIVITY_TYPE.file_upload]: 'File Upload',
  [ACTIVITY_TYPE.ftp]: 'FTP Upload',
  [ACTIVITY_TYPE.api]: 'API Access',
};

export const ACTIVITY_FILE_TYPE = {
  excel: 'excel',
  xml: 'xml',
};

export const ACTIVITY_FILE_TYPE_LABEL = {
  [ACTIVITY_FILE_TYPE.excel]: 'Excel',
  [ACTIVITY_FILE_TYPE.xml]: 'XML',
};

export const ACTIVITY_ERROR_CODE = {
  field_required: 'field_required',
  ab_invalid: 'ab_invalid',
  invalid_guid: 'invalid_guid',
  field_wrong_format: 'field_wrong_format',
  field_wrong_parent: 'field_wrong_parent',
  invalid_character_limit: 'invalid_character_limit',
  country_mapping_required: 'country_mapping_required',
  field_mapping_required: 'field_mapping_required',
  data_mapping_required: 'data_mapping_required',
  identity_number_not_exist: 'identity_number_not_exist',
  invalid_characters: 'invalid_characters',
  field_wrong_date_format: 'field_wrong_date_format',
  standard_mapping_required: 'standard_mapping_required',
  mncb_cb_link_required: 'mncb_cb_link_required',
  field_missing_mapping_required: 'field_missing_mapping_required',
  sheet_not_exist: 'sheet_not_exist',
  certification_status_mapping_required:
    'certification_status_mapping_required',
  invalid_ce_guid: 'invalid_ce_guid',
  ftp_file_not_found: 'ftp_file_not_found',
  ftp_connection_error: 'ftp_connection_error',
  invalid_xml_file: 'invalid_xml_file',
  invalid_data: 'invalid_data',
  certification_standard_scheme_required:
    'certification_standard_scheme_required',
  // inactive_mapping_exists: 'inactive_mapping_exists',
  inactive_field_mapping_exists: 'inactive_field_mapping_exists',
  inactive_data_mapping_exists: 'inactive_data_mapping_exists',
  accreditation_not_exist: 'accreditation_not_exist',
  invalid_accreditation_status: 'invalid_accreditation_status',
  certification_max_upload_time_exceeded:
    'certification_max_upload_time_exceeded',
  certification_invalid_issue_date: 'certification_invalid_issue_date',
  certification_invalid_expiry_date: 'certification_invalid_expiry_date',
  certification_invalid_renewal_dates: 'certification_invalid_renewal_dates',
  certification_invalid_renewal_issue_date:
    'certification_invalid_renewal_issue_date',
  invalid_expired_status_expiry_date: 'invalid_expired_status_expiry_date',
  invalid_active_status_expiry_date: 'invalid_active_status_expiry_date',
  cb_invalid_issue_date: 'cb_invalid_issue_date',
  cb_invalid_expiry_date: 'cb_invalid_expiry_date',
  data_char_limit_exceeded: 'data_char_limit_exceeded',
  sector_accreditation_status_invalid: 'sector_accreditation_status_invalid',
  economy_accreditation_status_invalid: 'economy_accreditation_status_invalid',
  field_mismatch_standard_scheme: 'field_mismatch_standard_scheme',
  cb_accreditation_status_invalid: 'cb_accreditation_status_invalid',
  sector_mapping_required: 'sector_mapping_required',
  data_required: 'data_required',
  data_skip_duplicate_identifier_file: 'data_skip_duplicate_identifier_file',
  country_invalid: 'country_invalid',
  data_skip_duplicate_identifier_system:
    'data_skip_duplicate_identifier_system',
};

export const ACTIVITY_ERROR_MESSAGE = {
  [ACTIVITY_ERROR_CODE.data_required]: 'Required data missing',
  [ACTIVITY_ERROR_CODE.field_required]: 'Required data missing',
  [ACTIVITY_ERROR_CODE.ab_invalid]: 'Invalid Accreditation Body',
  [ACTIVITY_ERROR_CODE.invalid_guid]: 'Invalid identity number',
  [ACTIVITY_ERROR_CODE.field_wrong_format]: 'Invalid data format',
  [ACTIVITY_ERROR_CODE.field_wrong_parent]: 'Invalid data dependencies',
  [ACTIVITY_ERROR_CODE.invalid_character_limit]: 'Exceeds character limit',
  [ACTIVITY_ERROR_CODE.country_mapping_required]: 'Country mapping required',
  [ACTIVITY_ERROR_CODE.field_mapping_required]: 'Field mapping required',
  [ACTIVITY_ERROR_CODE.data_mapping_required]: 'Data mapping required',
  [ACTIVITY_ERROR_CODE.identity_number_not_exist]:
    'Not existing identity number',

  [ACTIVITY_ERROR_CODE.invalid_characters]: 'Invalid characters are detected',
  [ACTIVITY_ERROR_CODE.field_wrong_date_format]: 'Invalid date format',
  [ACTIVITY_ERROR_CODE.standard_mapping_required]: 'Standard mapping required',
  [ACTIVITY_ERROR_CODE.mncb_cb_link_required]:
    'Certification Body is not a subsidiary.',
  [ACTIVITY_ERROR_CODE.field_missing_mapping_required]:
    'Missing field in excel data sheet',
  [ACTIVITY_ERROR_CODE.sheet_not_exist]: 'Sheet Name does not exist',
  [ACTIVITY_ERROR_CODE.certification_status_mapping_required]:
    'Invalid Certification Status',
  [ACTIVITY_ERROR_CODE.invalid_ce_guid]: 'Invalid CE Unique ID',
  [ACTIVITY_ERROR_CODE.ftp_file_not_found]: 'Data import failed: No such file',
  [ACTIVITY_ERROR_CODE.ftp_connection_error]:
    'Data import failed: Could not connect to server',
  [ACTIVITY_ERROR_CODE.invalid_data]: 'Invalid data',
  [ACTIVITY_ERROR_CODE.certification_standard_scheme_required]:
    'Missing Standards and Schemes',
  // [ACTIVITY_ERROR_CODE.inactive_mapping_exists]:
  //   'Inactive data mapping needs to be resolved.',
  [ACTIVITY_ERROR_CODE.inactive_field_mapping_exists]:
    'Inactive field mapping needs to be resolved.',
  [ACTIVITY_ERROR_CODE.inactive_data_mapping_exists]:
    'Inactive data mapping needs to be resolved.',
  [ACTIVITY_ERROR_CODE.invalid_accreditation_status]: (status) =>
    `Accreditation Status is ${status}`, //The CB wants to upload certs under AB, but AB hasn't accredited this CB.
  [ACTIVITY_ERROR_CODE.accreditation_not_exist]: 'Accreditation does not exist',
  [ACTIVITY_ERROR_CODE.certification_invalid_issue_date]: 'Invalid issue date',
  [ACTIVITY_ERROR_CODE.certification_invalid_expiry_date]:
    'Invalid expiry date',
  [ACTIVITY_ERROR_CODE.certification_invalid_renewal_dates]:
    'Invalid Certification renewal date',
  [ACTIVITY_ERROR_CODE.certification_invalid_renewal_issue_date]:
    'Invalid Certification renewal date',
  [ACTIVITY_ERROR_CODE.invalid_expired_status_expiry_date]:
    'Expiry date does not conform to the certification status.',
  [ACTIVITY_ERROR_CODE.invalid_active_status_expiry_date]:
    'Expiry date does not conform to the certification status.',
  [ACTIVITY_ERROR_CODE.cb_invalid_issue_date]: 'Invalid issue date',
  [ACTIVITY_ERROR_CODE.cb_invalid_expiry_date]: 'Invalid expiry date',
  [ACTIVITY_ERROR_CODE.certification_max_upload_time_exceeded]:
    'Upload time limit was reached',
  [ACTIVITY_ERROR_CODE.sector_accreditation_status_invalid]:
    'Invalid Accreditation Status',
  [ACTIVITY_ERROR_CODE.economy_accreditation_status_invalid]:
    'Invalid Accreditation Status',
  [ACTIVITY_ERROR_CODE.field_mismatch_standard_scheme]:
    'Invalid Standard and Scheme',
  [ACTIVITY_ERROR_CODE.cb_accreditation_status_invalid]:
    'Accreditation is invalid',
  [ACTIVITY_ERROR_CODE.data_skip_duplicate_identifier_file]:
    'Duplicated Identifier',
  [ACTIVITY_ERROR_CODE.data_skip_duplicate_identifier_system]:
    'Duplicated Identifier',
  [ACTIVITY_ERROR_CODE.country_invalid]: 'Invalid Country',
};

export const ACTIVITY_LABEL = {
  error: 'Error',
  success: 'Success',
  partially_verified: 'Partially Verified',
  uploading: 'Uploading',
  verifying: 'Verifying',
  matching: 'Matching',
};

export const ACTIVITY_RESULT_STATUS = {
  error: 'error',
  success: 'success',
  partially_verified: 'partially_verified',
};

export const ACTIVITY_RESULT_STATUS_COLOR = {
  [ACTIVITY_RESULT_STATUS.error]: 'danger',
  [ACTIVITY_RESULT_STATUS.success]: 'success',
  [ACTIVITY_RESULT_STATUS.partially_verified]: 'warning',
};

export const ACTIVITY_STATES = {
  uploading: 'uploading',
  verifying: 'verifying',
  matching: 'matching',
};
