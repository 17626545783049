import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {CDN_IMAGE_URL} from 'configs';
import Image from './Image';

class Component extends React.Component {
  static propTypes = {
    code: PropTypes.string.isRequired,
    name: PropTypes.string,
  };

  get className() {
    const {code, className} = this.props;
    const classes = !code ? {} : {[`flag-${code.toLowerCase()}`]: code};
    return classNames('flag', classes, className);
  }

  render() {
    const {name} = this.props;

    return (
      <Image
        className={this.className}
        src={`${CDN_IMAGE_URL}/flags/24/blank.gif`}
        alt={name}
      />
    );
  }
}

export default Component;
