import React from 'react';
import {Label} from 'reactstrap';

LabelRequired.defaultProps = {
  required: true,
  className: '',
};

function LabelRequired({required, label, className}) {
  return (
    <Label>
      <span className={className}>{label}</span>
      {required && <span className="text-danger ml-1">*</span>}
    </Label>
  );
}

export default LabelRequired;
