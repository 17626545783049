import styles from './styles.module.scss';
import React, {useState} from 'react';

import {
  FormGroup,
  Modal,
  ModalBody,
  ButtonGroup,
  Button,
  ModalHeader,
} from 'reactstrap';
import {SearchSelectCE, SearchSelectCert} from 'components/Forms/Searches';
import {CaptchaModal} from 'components/Captcha';
import DataLayerPush from 'utils/gtm';

import {first, isEmpty, slice} from 'lodash';
import {useLocation} from 'react-router';
import {components} from 'react-select';
import classNames from 'classnames';

const SEARCH_TYPE = {
  ce: 'certified-entity',
  cert: 'certification',
};

const CustomOption = (props) => {
  const {data, ...rest} = props;
  const {cert_number, ces = []} = data || {};

  if (!ces) {
    return <components.Option {...rest}>{cert_number}</components.Option>;
  }

  const firstCE = first(ces) || '';
  const restCEs = slice(ces, 1);

  return (
    <components.Option {...rest}>
      <p
        className={classNames('text-dark font-weight-bold p-0', ces && 'mb-1')}
        style={{fontSize: '12px'}}
      >
        {cert_number}
      </p>

      {ces && (
        <div>
          {firstCE && (
            <p
              class="text-secondary p-0 m-0"
              style={{fontSize: '12px'}}
              title={firstCE}
            >
              <span style={{overflowWrap: 'break-word'}}>{firstCE}</span>
              {restCEs.length > 0 && (
                <span
                  style={{color: 'var(--gray)', filter: 'opacity(0.5)'}}
                  className="ml-1"
                >
                  (+{restCEs.length} more)
                </span>
              )}
            </p>
          )}
        </div>
      )}
    </components.Option>
  );
};

function PrivateSearchModal(props) {
  const {isOpen, toggleOpen} = props;
  const {pathname} = useLocation();

  const [searchType, setSearchType] = useState(SEARCH_TYPE.ce);
  const {eventSearchClick, eventSearchInput} = DataLayerPush;

  function handleChangeType(e) {
    setSearchType(e.target.value);
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleOpen}
      size="lg"
      className={styles['private__search__modal']}
    >
      <ModalHeader toggle={toggleOpen} className="pb-0 border-0">
        Search
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <ButtonGroup className="w-100">
            <Button
              id="certified-entity"
              value={SEARCH_TYPE.ce}
              onClick={handleChangeType}
              color="primary"
              outline
              active={searchType === SEARCH_TYPE.ce}
            >
              Company Name
            </Button>

            <Button
              id="certification"
              value={SEARCH_TYPE.cert}
              onClick={handleChangeType}
              color="primary"
              outline
              active={searchType === SEARCH_TYPE.cert}
            >
              Certification ID
            </Button>
          </ButtonGroup>
        </FormGroup>

        <FormGroup className="mb-0">
          <CaptchaModal initialCheck={false}>
            {({verifyCaptcha}) => {
              return (
                <div onClick={verifyCaptcha}>
                  {searchType === 'certified-entity' ? (
                    <SearchSelectCE
                      key={pathname}
                      verifyCaptcha={verifyCaptcha}
                      onInputChange={(input, result) => {
                        eventSearchInput({
                          searchInputEntityType: 'ce',
                          searchInputLocation: 'header',
                          searchInputKeyword: input,
                          searchInputResult: !isEmpty(result),
                        });
                      }}
                      onSelect={(selected, keyword) => {
                        const {company_id, company_name} = selected || {};

                        if (!isEmpty(selected)) {
                          eventSearchClick({
                            searchClickEntityType: 'ce',
                            searchClickLocation: 'header',
                            searchClickKeyword: keyword,
                            searchClickEntityName: company_name,
                            searchClickEntityId: company_id,
                          });

                          toggleOpen();
                        }
                      }}
                    />
                  ) : (
                    <SearchSelectCert
                      key={pathname}
                      verifyCaptcha={verifyCaptcha}
                      onInputChange={(input, result) => {
                        eventSearchInput({
                          searchInputEntityType: 'cert',
                          searchInputLocation: 'header',
                          searchInputKeyword: input,
                          searchInputResult: !isEmpty(result),
                        });
                      }}
                      onSelect={(selected, keyword) => {
                        const {
                          company_certifications_id,
                          certification_number,
                        } = selected || {};

                        if (!isEmpty(selected)) {
                          eventSearchClick({
                            searchClickEntityType: 'cert',
                            searchClickLocation: 'header',
                            searchClickKeyword: keyword,
                            searchClickEntityName: company_certifications_id,
                            searchClickEntityId: certification_number,
                          });

                          toggleOpen();
                        }
                      }}
                      components={{
                        Option: CustomOption,
                      }}
                    />
                  )}
                </div>
              );
            }}
          </CaptchaModal>
        </FormGroup>
      </ModalBody>
    </Modal>
  );
}

export default PrivateSearchModal;
