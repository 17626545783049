import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';

import {IAF_LOGOS} from 'configs';
import {Image} from 'components/Images';

const Component = ({className}) => {
  const _className = classNames(styles['image-logo-iaf'], className);

  return <Image className={_className} src={IAF_LOGOS['lg']} alt="IAF Logo" />;
};

export default Component;
