import React from 'react';
import {Progress} from 'reactstrap';

import PropTypes from 'prop-types';

const PROGRESS_COLORS = {
  default: 'primary',
  warning: 'warning',
  completed: 'danger',
};

ProgressStats.defaultProps = {
  colors: PROGRESS_COLORS,
};

ProgressStats.propTypes = {
  colors: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  value: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  maxValue: PropTypes.number.isRequired,
};

export function getProgressColor(
  value,
  maxValue,
  colors = {},
  reverse = false
) {
  const percentage = (value / maxValue) * 100;

  if (reverse) {
    if (percentage <= 0) {
      return colors.completed || PROGRESS_COLORS.completed;
    } else if (percentage <= 10) {
      return colors.warning || PROGRESS_COLORS.warning;
    } else {
      return colors.default || PROGRESS_COLORS.default;
    }
  } else {
    if (percentage === 100 || percentage > 100) {
      return colors.completed || PROGRESS_COLORS.completed;
    } else if (percentage >= 90) {
      return colors.warning || PROGRESS_COLORS.warning;
    } else {
      return colors.default || PROGRESS_COLORS.default;
    }
  }
}

function ProgressStats(props) {
  const {isLoading, maxValue, value, colors, reverse} = props;
  const progressColor = getProgressColor(value, maxValue, colors, reverse);

  return (
    <Progress
      value={value}
      max={maxValue}
      animated={isLoading}
      barClassName={`bg-${progressColor}`}
    />
  );
}

export default ProgressStats;
