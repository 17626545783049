import React from 'react';

import {Nav} from 'reactstrap';

import PrivateNavPlan from './PrivateNavPlan';
import PrivateNavUser from './PrivateNavUser';
import PrivateNavLimit from './PrivateNavLimit';
import PrivateNavSearch from './PrivateNavSearch';
import PrivateNavNotification from './PrivateNavNotification';
import PrivateSearchModal from './PrivateSearchModal';

import {useAuth} from 'contexts/AuthContext';
import {useLimits} from 'contexts/LimitContext';
import {useSubscription} from 'contexts/SubscriptionContext';
import {usePage} from 'contexts/PageContext';
import usePrivateNavSearchModal from './usePrivateNavSearchModal';

import {debounce, get, includes, keys, pick} from 'lodash';
import {IconFA} from 'components/Icons';
import cx from 'classnames';
import styles from './styles.module.scss';

import {ACCOUNT_ROLES} from 'configs';
import {COMPANY_TYPE} from 'configs/types';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';

function PrivateNav(props) {
  const {dark} = props;
  const {setSidebar, isOpenLeftSidebar, sidebar} = usePage();

  const auth = useAuth();
  const {limit} = useLimits();
  const {isSubscribed} = useSubscription();
  const isSuperAdmin =
    get(auth, 'account.account_role') === ACCOUNT_ROLES.super_admin;

  const accountType = get(auth, 'companyType');
  const vuAccounts = [COMPANY_TYPE.ce, COMPANY_TYPE.company];
  const isVU = includes(keys(pick(COMPANY_TYPE, vuAccounts)), accountType);

  const {isOpen, toggleOpen} = usePrivateNavSearchModal();

  const toggleSidebar = debounce(() => {
    setSidebar('left', !isOpenLeftSidebar);
  }, 100);

  return (
    <>
      {!!sidebar && (
        <span
          onClick={toggleSidebar}
          className={cx('d-xl-none p-1 ml-2', styles['cursor-pointer'])}
        >
          <IconFA name={isOpenLeftSidebar ? 'step-backward' : 'step-forward'} />
        </span>
      )}
      <span
        onClick={toggleOpen}
        className={cx('d-xl-none p-1', styles['cursor-pointer'], {
          'ml-2': !sidebar,
        })}
      >
        <IconFA name="search" />
      </span>

      <div
        className={cx(
          'd-none d-xl-block',
          styles['private__nav__search__container']
        )}
      >
        <PrivateNavSearch />
      </div>

      <Nav className="align-items-center ml-auto flex-row" navbar>
        {ENABLE_SUBSCRIPTION && isVU && (
          <>
            {limit >= 0 && isSubscribed && <PrivateNavLimit />}
            {isSuperAdmin && <PrivateNavPlan />}
          </>
        )}

        <PrivateNavNotification dark={dark} />

        <PrivateNavUser />
      </Nav>

      <PrivateSearchModal isOpen={isOpen} toggleOpen={toggleOpen} />
    </>
  );
}

export default PrivateNav;
