import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';

import {IMAGE_MARK} from 'configs';
import {Image} from 'components/Images';

const defaultProps = {
  size: 'md',
};

const ImageMark = ({size, className}) => {
  const _className = classNames(styles['image-mark'], className, {
    [styles[`image-mark--${size}`]]: size,
  });

  return (
    <Image className={_className} src={IMAGE_MARK} alt="IAF CertSearch Mark" />
  );
};

ImageMark.defaultProps = defaultProps;

export default ImageMark;
