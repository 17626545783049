import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty, isObject} from 'lodash/lang';
import classNames from 'classnames';
import {DEFAULT_IMAGES, IAF_LOGOS, CDN_URL} from 'configs';

class Component extends React.Component {
  static propTypes = {
    image: PropTypes.string,
  };

  static defaultProps = {
    image: DEFAULT_IMAGES[160],
    size: 160,
  };

  parse(image) {
    try {
      return JSON.parse(image);
    } catch (err) {
      return {};
    }
  }

  get image() {
    const {image, qtProducts} = this.props;

    if (!isEmpty(image)) {
      const objFile = this.parse(image);

      if (isObject(objFile) && isEmpty(image)) {
        if (image === 'logo_iaf') {
          return IAF_LOGOS['lg'];
        } else {
          return DEFAULT_IMAGES[160];
        }
      }

      const {files_disk_path, files_thumbnail_name} = objFile;

      if (qtProducts) {
        return `${files_disk_path}/${files_thumbnail_name[this.props.size]}`;
      } else {
        return `${CDN_URL}${files_disk_path}/${
          files_thumbnail_name[this.props.size]
        }`;
      }
    }

    return '';
  }

  render() {
    const _className = classNames(styles['image-background'], {});
    const _style = {
      background: `url(${this.image}) 100%`,
      width: '100%',
      height: '235px',
      backgroundSize: 'cover',
    };
    return <div className={_className} style={_style} />;
  }
}

export default Component;
