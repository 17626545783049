import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';
import {withViewport} from 'contexts/ViewportContext';
import {VIEWPORT_WIDTHS} from 'configs';
import {withPage} from 'contexts/PageContext';
import {withRouter} from 'react-router';

class Component extends React.Component {
  static defaultProps = {
    side: 'left',
  };

  // close sidebar and backdrop when screen resizes or pathname changes
  componentDidUpdate(prevProps, prevState) {
    if (
      VIEWPORT_WIDTHS[prevProps.viewport.size] !==
      VIEWPORT_WIDTHS[this.props.viewport.size]
    ) {
      this.props.page.setSidebar('both', false);
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.page.setSidebar('both', false);
    }
  }

  render() {
    const {side, showAt, width, children} = this.props;
    const {size} = this.props.viewport;
    const {isOpenLeftSidebar} = this.props.page;

    const isMobile = VIEWPORT_WIDTHS[size] < VIEWPORT_WIDTHS[showAt];

    return (
      <aside
        style={{width: width}}
        className={classNames(styles['page__sidebar'], {
          [styles[`page__sidebar--${side}`]]: !!side,
          [styles[`page__sidebar--overlay`]]: isMobile,
          [styles[`page__sidebar--overlay--closed`]]:
            isMobile && !isOpenLeftSidebar,
        })}
      >
        <div style={{width: width}} className={styles['page__sidebar__inner']}>
          {children}
        </div>
      </aside>
    );
  }
}

export default withViewport(withPage(withRouter(Component)));
