import React from 'react';
import {BaseButton} from 'components/Buttons';
import {useLimits} from 'contexts/LimitContext';
import {useSubscription} from 'contexts/SubscriptionContext';
import {SUBSCRIPTION_PLANS_TYPE} from 'configs/subscription';

function PrivateNavPlan() {
  const {limit, totalLimit} = useLimits();
  const {subscriptionPlan, isSubscribed} = useSubscription();

  const isPremium = subscriptionPlan === SUBSCRIPTION_PLANS_TYPE.premium;

  const isNearLimit = (limit / totalLimit) * 100 <= 30;

  if ((isSubscribed && !isNearLimit) || isPremium) {
    return false;
  }

  return (
    <div className="d-flex align-items-center">
      <BaseButton
        to="/membership/plans?redirect=dashboard"
        className="mr-3 rounded-sm"
        color="danger"
        outline
      >
        Upgrade Plan
      </BaseButton>
    </div>
  );
}

export default PrivateNavPlan;
