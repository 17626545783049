import styles from './styles.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LoadingSpinner from './LoadingSpinner';

const defaultProps = {
  isLoading: true,
};

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

function LoadingPage(props) {
  const {isLoading, className} = props;

  if (!isLoading) return null;

  return (
    <div
      className={classNames(styles['page__loading__backdrop'], {
        [className]: className,
      })}
    >
      <LoadingSpinner isLoading={isLoading} />
    </div>
  );
}

LoadingPage.defaultProps = defaultProps;
LoadingPage.propTypes = propTypes;

export default LoadingPage;
