import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {Row, Col, Button, Spinner} from 'reactstrap';
import {IconFA} from 'components/Icons';
import {
  PROGRESS_LINKS,
  PROGRESS_KEYS,
  PROGRESS_STEPS_BUTTON,
} from 'configs/progress';

function ProgressButton(props) {
  const {
    isLoading,
    progress,
    doneCount,
    totalCount,
    toastShow,
    justFinished,
    setToastShow,
  } = props;
  let isThisPageDone;
  let fakeDoneCount;

  const isAtCompanyProfile = useRouteMatch([
    PROGRESS_LINKS.company_company_profile,
    PROGRESS_LINKS.default_company_profile,
    PROGRESS_LINKS.not_default_company_profile,
    PROGRESS_LINKS.ce_company_profile,
  ]);
  const isAtProfileSettings = useRouteMatch([PROGRESS_LINKS.user_information]);
  const isAtAuthenticator = useRouteMatch([
    PROGRESS_LINKS.user_two_factor_auth,
  ]);

  const isAtProgressPage =
    isAtCompanyProfile || isAtProfileSettings || isAtAuthenticator;

  if (isAtCompanyProfile) {
    isThisPageDone = progress[PROGRESS_KEYS.company_profile];
  } else if (isAtProfileSettings) {
    isThisPageDone = progress[PROGRESS_KEYS.user_information];
  } else if (isAtAuthenticator) {
    isThisPageDone = progress[PROGRESS_KEYS.user_two_factor_auth];
  }

  fakeDoneCount = isThisPageDone
    ? doneCount
    : isAtProgressPage
    ? doneCount + 1
    : doneCount;

  return (
    <Button
      color="primary"
      className="progress-container__button"
      onClick={() => {
        if (!toastShow) setToastShow(true);
      }}
      disabled={isLoading}
    >
      {isLoading && <Spinner size="sm" />}
      {!isLoading && (
        <React.Fragment>
          {fakeDoneCount === 0 && (
            <Row noGutters>
              <Col className="col-auto mr-2">
                <div className="progress-checkbox--button ">
                  <IconFA name="check" />
                </div>
              </Col>
              <Col className="flex-grow-1">
                <span>Getting Started with IAF CertSearch</span>
              </Col>
              <Col className="col-auto">
                <IconFA name="angle-up" className="fa-lg ml-3" />
              </Col>
            </Row>
          )}
          {!justFinished && fakeDoneCount > 0 && (
            <Row noGutters>
              <Col className="col-auto mr-1">
                <div className="progress-checkbox--button mr-2">
                  {(!!isThisPageDone || !isAtProgressPage) && (
                    <IconFA name="check" />
                  )}
                </div>
              </Col>
              <Col className="flex-grow-1">
                <span>
                  <span>
                    {fakeDoneCount} of {totalCount}{' '}
                  </span>
                  <span>
                    {isAtCompanyProfile &&
                      PROGRESS_STEPS_BUTTON.company_profile}
                  </span>
                  <span>
                    {isAtProfileSettings &&
                      PROGRESS_STEPS_BUTTON.user_information}
                  </span>
                  <span>
                    {isAtAuthenticator &&
                      PROGRESS_STEPS_BUTTON.user_two_factor_auth}
                  </span>
                  <span>
                    {!isAtProgressPage && 'Getting Started with IAF CertSearch'}
                  </span>
                </span>
              </Col>
              <Col className="col-auto">
                <IconFA name="angle-up" className="fa-lg ml-3" />
              </Col>
            </Row>
          )}
          {justFinished && (
            <Row noGutters>
              <Col className="col-auto mr-1">
                <div className="progress-checkbox--button mr-2">
                  {!!isThisPageDone && <IconFA name="check" />}
                </div>
              </Col>
              <Col className="flex-grow-1">
                <span>Congratulations! You are all setup.</span>
              </Col>
              <Col className="col-auto">
                <IconFA name="angle-up" className="fa-lg ml-3" />
              </Col>
            </Row>
          )}
        </React.Fragment>
      )}
    </Button>
  );
}

export default ProgressButton;
