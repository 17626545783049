import styles from './styles.module.scss';

import React from 'react';
import cx from 'classnames';
import {Helmet} from 'react-helmet';
import {isEmpty} from 'lodash/lang';
import {get} from 'lodash/object';

import {withPage} from 'contexts/PageContext';

import {LoadingPage} from 'components/Loading';
import PageSidebar from './PageSidebar';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';

class Page extends React.Component {
  get pageTitle() {
    return (
      <Helmet>
        <title>{this.props.title}</title>
      </Helmet>
    );
  }

  get pageHeader() {
    const {header} = this.props.page;

    return header && (header === true ? <PageHeader /> : header);
  }

  get pageCenter() {
    const {isLoading} = this.props.page;

    return (
      <div className="col" style={{overflow: 'hidden'}}>
        <div className={styles['page__center']}>
          {isLoading && <LoadingPage isLoading={isLoading} />}

          <div className={styles['page__main']}>{this.props.children}</div>

          {this.pageFooter}
        </div>
      </div>
    );
  }

  get pageLeft() {
    const {sidebar} = this.props.page;

    if (isEmpty(get(sidebar, 'left'))) return null;

    return (
      <div className="col-auto">
        <PageSidebar
          side="left"
          showAt={sidebar.left.showAt}
          width={sidebar.left.width}
        >
          {sidebar.left.component}
        </PageSidebar>
      </div>
    );
  }

  get pageRight() {
    const {sidebar} = this.props.page;

    if (isEmpty(get(sidebar, 'right'))) return null;

    return (
      <div className="col-auto">
        <PageSidebar
          side="right"
          showAt={sidebar.right.showAt}
          width={sidebar.right.width}
        >
          {sidebar.right.component}
        </PageSidebar>
      </div>
    );
  }

  get pageFooter() {
    const {footer} = this.props.page;

    return footer && <PageFooter />;
  }

  get pageBackdrop() {
    const {isOpenLeftSidebar, isOpenRightSidebar, setSidebar} = this.props.page;

    if (!isOpenLeftSidebar && !isOpenRightSidebar) return null;

    return (
      <div
        className={styles['page__backdrop']}
        onClick={() => setSidebar('both', false)}
      />
    );
  }

  render() {
    return (
      <div
        className={cx(styles['page'], {
          [styles['page--has-header']]: !!this.props.page.header,
        })}
      >
        {this.pageTitle}
        {this.pageHeader}

        <div className="row flex-xl-nowrap no-gutters">
          {this.pageLeft}
          {this.pageCenter}
          {this.pageRight}
        </div>

        {this.pageBackdrop}
      </div>
    );
  }
}

export default withPage(Page);
