import {Badge} from 'reactstrap';
import React from 'react';
import classNames from 'classnames';

import {STATUS_LABELS} from 'configs';

const LabelBadge = (props) => {
  const {status, color, large, className, ...rest} = props;
  const _status = STATUS_LABELS[status] || 'Unknown Status';
  return (
    <Badge
      color={color}
      className={classNames(className && className)}
      {...rest}
    >
      {_status}
    </Badge>
  );
};

export default LabelBadge;
