import urlJoin from 'url-join';
import {
  CDN_FILE_URL,
  DATA_MANAGEMENT_TEMPLATE,
  USER_GUIDE_TEMPLATE,
} from 'configs/Environment';

const templateUrl = urlJoin(
  CDN_FILE_URL,
  '/assets/t',
  DATA_MANAGEMENT_TEMPLATE
); // Data management templates
const templateUrl1 = urlJoin(CDN_FILE_URL, '/assets/g', USER_GUIDE_TEMPLATE); // User Guides templates
const templateUrlSupportLetter = urlJoin(
  CDN_FILE_URL,
  'assets/support-letters'
); // Support Letters templates

function getTemplate(type) {
  return urlJoin(templateUrl, type);
}

function getTemplate1(type) {
  return urlJoin(templateUrl1, type);
}

function getTemplateLetterSupport(type) {
  return urlJoin(templateUrlSupportLetter, type);
}

export const DATA_TEMPLATE = {
  ab_cbs_excel: getTemplate('ab_cbs.xlsx'),
  ab_cbs_excel_sample: getTemplate('ab_cbs_sample.xlsx'),
  ab_cbs_excel_instruction: getTemplate('ab_cbs_excel_instructions.xlsx'),
  ab_cbs_xml: getTemplate('ab_cbs.xml'),
  ab_cbs_xml_sample: getTemplate('ab_cbs_sample.xml'),
  ab_cbs_xml_instruction: getTemplate('ab_cbs_xml_instructions.xlsx'),
  ab_certs_excel: getTemplate('ab_certs.xlsx'),
  ab_certs_excel_sample: getTemplate('ab_certs_sample.xlsx'),
  ab_certs_excel_instruction: getTemplate('ab_certs_excel_instructions.xlsx'),
  ab_certs_xml: getTemplate('ab_certs.xml'),
  ab_certs_xml_sample: getTemplate('ab_certs_sample.xml'),
  ab_certs_xml_instruction: getTemplate('ab_certs_xml_instructions.xlsx'),
  cb_certs_excel: getTemplate('cb_certs.xlsx'),
  cb_certs_excel_sample: getTemplate('cb_certs_sample.xlsx'),
  cb_certs_excel_instruction: getTemplate('cb_certs_excel_instructions.xlsx'),
  cb_certs_xml: getTemplate('cb_certs.xml'),
  cb_certs_xml_sample: getTemplate('cb_certs_sample.xml'),
  cb_certs_xml_instruction: getTemplate('cb_certs_xml_instructions.xlsx'),
  mncb_certs_xml: getTemplate('mncb_certs.xml'),
  mncb_certs_xml_sample: getTemplate('mncb_certs_sample.xml'),

  cb_webinars: getTemplate('Certification%20Body%20Webinars.pdf'),
  cert_brochure_cb: getTemplate1('IAF_CertSearch_Brochure_CB.pdf'),
  cert_brochure_ce: getTemplate1('IAF_CertSearch_Brochure_CE.pdf'),
  cert_info_guide_cb: getTemplate1('IAF_CertSearch_Information_Guide_CB.pdf'),
  legitimate_interest_assestment: getTemplate(
    'Legitimate Interest Assessment & Summary.pdf'
  ),
  iaf_cert_intro_presentation: getTemplate1(
    'IAF_CertSearch_Introductory_Presentation.pdf'
  ),
  ab_general_user_guide: getTemplate(''),

  letter_support_iea: getTemplateLetterSupport('Letter_of_Support-IEA.pdf'),
  letter_support_nrcan: getTemplateLetterSupport(
    '/Letter_of_Support-NRCan.pdf'
  ),
  letter_support_unido: getTemplateLetterSupport('Letter_of_Support-UNIDO.pdf'),
  letter_support_usdoe: getTemplateLetterSupport(
    '/Letter_of_Support-USDOE.pdf'
  ),
};

export const VIDEO_GUIDE = {
  introduce_iaf: 'https://youtu.be/P2pEwXVrfUg',
  activate_ab: 'https://youtu.be/5Az_EMLbvzY',
  activate_cb: 'https://youtu.be/2V5yJKPPVGM',
  add_cb: 'https://youtu.be/94FKWGcbJZ8',
  add_standard: 'https://youtu.be/mDEFvD63o00',
  file_upload: 'https://youtu.be/zvO_9HvnJ-A',
  ftp_access: 'https://youtu.be/lzT_75T3_UQ',
  data_mapping: 'https://youtu.be/8dE0nBU29_E',
};

export const DOC_GUIDE = {
  accounts:
    'https://developer.iafcertsearch.org/general-instructions/p/accounts',
  cbs: 'https://developer.iafcertsearch.org/general-instructions/p/certification-bodies',
  certs:
    'https://developer.iafcertsearch.org/general-instructions/p/certifications',
  ces: 'https://developer.iafcertsearch.org/general-instructions/p/certified-entities',
  standards:
    'https://developer.iafcertsearch.org/general-instructions/p/standards',
  users: 'https://developer.iafcertsearch.org/general-instructions/p/users',
  settings:
    'https://developer.iafcertsearch.org/general-instructions/p/settings',
};

export const DEV_GUIDE = {
  file_upload: 'https://developer.iafcertsearch.org/file-upload',
  ftp_access: 'https://developer.iafcertsearch.org/ftp-access',
  api_access: 'https://developer.iafcertsearch.org/api-access',
  data_mapping: 'https://developer.iafcertsearch.org/data-mapping',
  ab_link:
    'https://developer.iafcertsearch.org/field-names/accreditation-body-acronyms',
};

const ExternalUrl = {
  DATA_TEMPLATE,
  VIDEO_GUIDE,
  DOC_GUIDE,
  DEV_GUIDE,
};

export default ExternalUrl;
