import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import cleanDeep from 'clean-deep';

const propTypes = {
  responsive: PropTypes.bool,
  noIndex: PropTypes.bool,
  title: PropTypes.string,
  keywords: PropTypes.string,
  description: PropTypes.string,
  viewport: PropTypes.string,
};

const defaultProps = {
  responsive: false,
  noIndex: false,
  title: 'IAF Certification Validation - IAF CertSearch',
  description: `IAF CertSearch is the exclusive global database for accredited management system certifications allowing users to validate an organization's certification(s).`,
  canonical: '',
  keywords: 'IAF, ISO Standard, Verify certificate, Certification verification',
};

function PageHelmet(props) {
  const {
    titleTemplate,
    title,
    description,
    canonical,
    pathname,
    image,
    children,
    noIndex,
    keywords,
    viewport,
  } = props;

  const meta = [
    noIndex && {name: 'robots', content: 'noindex'},

    description && {
      name: 'description',
      content: description,
    },
    /** Open Graph / Facebook **/
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: urlJoin(
        window.location.origin,
        pathname || window.location.pathname
      ),
    },
    title && {
      property: 'og:title',
      content: title,
    },
    description && {
      property: 'og:description',
      content: description,
    },
    image && {
      property: 'og:image',
      content: image,
    },
    /** Twitter **/
    image && {
      property: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      property: 'twitter:url',
      content: urlJoin(
        window.location.origin,
        pathname || window.location.pathname
      ),
    },
    title && {
      property: 'twitter:title',
      content: title,
    },
    description && {
      property: 'twitter:description',
      content: description,
    },
    image && {
      property: 'twitter:image',
      content: image,
    },
    keywords && {
      property: 'keywords',
      content: keywords,
    },
    viewport && {
      property: 'meta',
      name: 'viewport',
      content: viewport,
    },
  ].filter((v) => v);

  const link = [
    {
      rel: 'canonical',
      href: urlJoin(
        window.location.origin,
        canonical || window.location.pathname
      ),
    },
  ];

  return (
    <Helmet
      titleTemplate={titleTemplate}
      title={title}
      meta={cleanDeep(meta)}
      link={cleanDeep(link)}
    >
      {children}
    </Helmet>
  );
}

PageHelmet.propTypes = propTypes;
PageHelmet.defaultProps = defaultProps;

export default PageHelmet;
