import styles from './styles.module.scss';

import React from 'react';
import {Link} from 'react-router-dom';
import cx from 'classnames';
import {Navbar, Container} from 'reactstrap';

import {ImageLogo} from 'components/Images';

const Header = ({children}) => {
  return (
    <header className={styles['local-layout__header']}>
      <Navbar light>
        <Link to="/">
          <ImageLogo />
        </Link>

        {children}
      </Navbar>
    </header>
  );
};

const Body = ({title, text, form = true, children, tagTitle = 'h1'}) => {
  return (
    <div className={styles['local-layout__content']}>
      <Container>
        <div
          className={cx(styles['local-layout__content__inner'], {
            [styles['local-layout__content__inner--form']]: form,
          })}
        >
          {React.createElement(
            tagTitle,
            {className: styles['local-layout__form__title']},
            title
          )}
          {text && <p className={styles['local-layout__form__text']}>{text}</p>}

          {children}
        </div>
      </Container>
    </div>
  );
};

const BodyRegister = ({children}) => {
  return (
    <div className={styles['local-layout__content--register']}>
      <Container>{children}</Container>
    </div>
  );
};

const Sidebar = ({title, text, children}) => {
  return (
    <div className={styles['local-layout__sidebar']}>
      <div className={styles['local-layout__sidebar__inner']}>
        <h2 className={styles['local-layout__sidebar__title']}>{title}</h2>
        <p className={styles['local-layout__sidebar__text']}>{text}</p>
        {children}
      </div>
    </div>
  );
};

export {Header, Body, BodyRegister, Sidebar};
