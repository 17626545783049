import styles from './styles.module.scss';
import {SIZES} from 'configs';
import {fileToImageUrl} from 'utils/images';

import React from 'react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';

class ImageAvatar extends React.Component {
  render() {
    let accountImage;
    const {image, name, size, ...rest} = this.props;
    if (image !== '[]') accountImage = fileToImageUrl(image, SIZES.md);
    return (
      <Avatar
        className={styles['image-avatar']}
        color="#107ad9"
        name={name}
        src={accountImage}
        size={size}
        round={true}
        maxInitials={2}
        {...rest}
      />
    );
  }
}

ImageAvatar.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.string,
};

export default ImageAvatar;
