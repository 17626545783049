export const PROGRESS_KEYS = {
  company_profile: 'company_profile',
  company_logo: 'company_logo',
  user_information: 'user_information',
  user_two_factor_auth: 'user_two_factor_auth',
};

export const PROGRESS_STEPS = {
  [PROGRESS_KEYS.company_profile]: 'Complete your company profile',
  [PROGRESS_KEYS.company_logo]: 'Upload your company logo',
  [PROGRESS_KEYS.user_information]: 'Complete your personal settings',
  [PROGRESS_KEYS.user_two_factor_auth]: 'Enable Additional Login Authenticator',
};

export const PROGRESS_STEPS_BUTTON = {
  [PROGRESS_KEYS.company_profile]: 'Complete your profile',
  [PROGRESS_KEYS.company_logo]: 'Upload your company logo',
  [PROGRESS_KEYS.user_information]: 'Update your personal settings',
  [PROGRESS_KEYS.user_two_factor_auth]: 'Update your login authenticators',
};

export const PROGRESS_HASHES = {
  [PROGRESS_KEYS.company_profile]: 'company_profile',
  [PROGRESS_KEYS.company_logo]: 'company_logo',
  [PROGRESS_KEYS.user_information]: 'user_information',
  [PROGRESS_KEYS.user_two_factor_auth]: 'user_two_factor_auth',
};

export const PROGRESS_LABELS = {
  [PROGRESS_KEYS.company_profile]: 'complete your company profile',
  [PROGRESS_KEYS.company_logo]: 'upload your company logo',
  [PROGRESS_KEYS.user_information]: 'update your personal settings',
  [PROGRESS_KEYS.user_two_factor_auth]:
    'enable additional login authenticators',
};

export const PROGRESS_LINKS = {
  ab_company_profile: '/ab-profile/accreditation-body',
  cb_company_profile: '/cb-profile/certification-body',
  ce_company_profile: '/ce-profile/profile',
  company_company_profile: '/company-profile/profile',
  default_company_profile: '/branches/:branchId',
  not_default_company_profile: '/settings/account',
  [PROGRESS_KEYS.user_information]: '/settings/user-profile',
  [PROGRESS_KEYS.user_two_factor_auth]: '/settings/security',
};
