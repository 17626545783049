import React, {useState} from 'react';
import cx from 'classnames';
import {isEmpty} from 'lodash';
import {Toast, Progress, Row, Col, Spinner, CustomInput} from 'reactstrap';
import {IconFA} from 'components/Icons';
import {PROGRESS_STEPS} from 'configs/progress';
import generateProgressLinks from './generateProgressLinks';
import {useAuth} from 'contexts/AuthContext';

function ProgressToast(props) {
  const {
    toastShow,
    setToastShow,
    isLoading,
    doneCount,
    totalCount,
    onSkip,
    progress,
  } = props;
  const [isChecked, setIsChecked] = useState();
  const {account} = useAuth();

  return (
    <Toast isOpen={toastShow} className="progress-container__toast">
      <div className="progress-container__toast__header">
        <h5 className="progress-container__toast__header__text">
          Getting Started with IAF CertSearch
        </h5>
        <IconFA
          name="angle-down"
          className="fa-lg"
          style={{cursor: 'pointer'}}
          onClick={() => setToastShow(false)}
        />
      </div>

      <hr className="m-0" />

      <div className="progress-container__toast__body">
        {isLoading && (
          <div
            style={{
              height: '150px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </div>
        )}

        {!isLoading && (
          <React.Fragment>
            <div className="mb-4">
              <p className="mb-1">
                {doneCount} of {totalCount} Get Started
              </p>
              <Progress
                value={
                  isEmpty(progress)
                    ? 0
                    : parseFloat(doneCount / totalCount).toFixed(2) * 100
                }
                striped
                color="primary"
              />
            </div>
            {Object.entries(progress || {}).map(([key, value], index) => {
              const isChecked = value;
              const text = PROGRESS_STEPS[key];
              const isLast = index === Object.keys(progress).length - 1;

              return (
                <div key={key} className="position-relative">
                  <Row className="text-muted">
                    <Col className="col-auto">
                      <div
                        className={cx('progress-checkbox', {
                          'progress-checkbox-checked': !!isChecked,
                          'progress-checkbox-unchecked': !isChecked,
                        })}
                      >
                        {!!isChecked && (
                          <IconFA name="check" className="text-white" />
                        )}
                      </div>
                    </Col>
                    <Col className="flex-grow-1">
                      <p className="mb-0 text">{text}</p>
                    </Col>
                    <Col className="col-auto">
                      <IconFA name="angle-right" className="fa-lg" />
                    </Col>
                  </Row>
                  {generateProgressLinks(key, account, () =>
                    setToastShow(false)
                  )}

                  {!isLast && <hr />}
                </div>
              );
            })}
          </React.Fragment>
        )}
      </div>

      <hr className="m-0" />
      {/* Toast Footer */}
      <div className="progress-container__toast__footer">
        <div className="progress-container__toast__footer__form">
          <CustomInput
            id="dont-show-again"
            type="checkbox"
            checked={isChecked}
            label="Do not show this again"
            onChange={(e) => {
              setIsChecked(e.target.checked);
            }}
          />
        </div>
        <span
          className="btn-link"
          style={{cursor: 'pointer'}}
          onClick={(e) => onSkip(isChecked)}
        >
          Skip these steps
        </span>
      </div>
    </Toast>
  );
}

export default ProgressToast;
