import styles from './styles.module.scss';
import React from 'react';
import {startCase} from 'lodash';
import {Link, useHistory} from 'react-router-dom';
import {isEmpty} from 'lodash/lang';
import classNames from 'classnames';

import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Badge,
  UncontrolledDropdown,
  DropdownItem,
  Button,
} from 'reactstrap';
import {LabelDate} from 'components/Labels';
import {IconFA} from 'components/Icons';
import {LoadingSpinner} from 'components/Loading';

import {
  NotificationProvider,
  NotificationConsumer,
} from 'contexts/NotificationContext';
import {ContentTitle} from 'components/Contents';
import {NOTIFICATION_CATEGORY_LABEL} from 'configs/notifications';
import {SUBSCRIPTION_PLANS_TYPE_LABEL} from 'configs/subscription';
import {ACTIVITY_RESULT_STATUS} from 'configs/data-management';
import dayjs from 'dayjs';

function NotificationDropItem(props) {
  const {onMarkRead, notification} = props;
  const {notification_id} = notification || {};

  return (
    <UncontrolledDropdown direction="down">
      <DropdownToggle nav color="transparent" className="text-secondary">
        <IconFA name="ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="py-2"
          onClick={() => onMarkRead(notification_id)}
        >
          <IconFA name="check-circle" className="text-secondary mr-1" />
          <span style={{fontSize: '14px'}}>Mark as read</span>
        </DropdownItem>
        {/* <DropdownItem className="py-2">
          <IconFA name="trash" className="text-secondary mr-1" />
          <span style={{fontSize: '14px'}}>Remove notification</span>
        </DropdownItem> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function NotificationDropAll({toggle}) {
  const history = useHistory();

  function handleClick() {
    if (toggle) toggle();
    history.push('/settings/notification');
  }

  return (
    <UncontrolledDropdown direction="down">
      <DropdownToggle nav color="transparent" className="text-secondary">
        <IconFA name="ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem className="py-2" onClick={handleClick}>
          <IconFA name="cog" className="text-secondary mr-1" />
          <span style={{fontSize: '14px'}}>Open Notifications Settings</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

function getNotificationProperties(code, details = {}) {
  let message, link, iconColor, icon;
  const {
    is_cert_upload,
    error_count,
    skipped_cert_count,
    uploaded_cert_count,
    file_name,
    verification_type,
  } = details;

  switch (code) {
    case 'subscription_success': {
      message = `Subscription to Free Plan Successful!`;
      icon = 'check-circle';
      iconColor = 'notification__icon--success';
      link = `/membership/overview`;
      break;
    }
    case 'subscription_payment_confirmation': {
      message = `Subscription Payment Successful`;
      icon = 'check-circle';
      iconColor = 'notification__icon--success';
      link = `/membership/overview`;
      break;
    }
    case 'subscription_upgrade_confirmation': {
      const {plan} = details || {};
      message = `Subscription Upgrade to ${SUBSCRIPTION_PLANS_TYPE_LABEL[plan]} Plan Successful!`;
      icon = 'check-circle';
      iconColor = 'notification__icon--success';
      link = `/membership/overview`;
      break;
    }
    case 'subscription_payment_confirmation_as': {
      message = 'Adding of User Seat Successful';
      icon = 'check-circle';
      iconColor = 'notification__icon--success';
      link = `/users`;
      break;
    }
    case 'near_verification_limit': {
      message = `Your ${startCase(verification_type)} Credits are Running Low!`;
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--warning';
      link = `/membership/overview`;
      break;
    }
    case 'out_of_credit': {
      message = `Out of ${startCase(verification_type)} Credit!`;
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      link = `/membership/overview`;
      break;
    }
    case 'subscription_payment_failed': {
      message = 'Subscription Payment Failed';
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      link = `/membership/overview`;
      break;
    }
    case 'subscription_payment_reminder': {
      message = 'Your payment method is about to expire';
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      link = `/membership/overview`;
      break;
    }
    case 'data_import_upload_success': {
      message = 'Bulk Import Completed Successfully';
      icon = 'check-circle';
      iconColor = 'notification__icon--success';
      link = `/import-management/activities`;
      break;
    }
    case 'data_import_upload_error': {
      message = 'Bulk Import Process Failed';
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      link = `/import-management/activities`;
      break;
    }
    case 'data_import_upload_partial': {
      message = 'Bulk Import Partially Successful';
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--warning';
      link = `/import-management/activities`;
      break;
    }
    case 'verification_grace_period_nearing_expiration': {
      const {company_name, cert_number, verification_expiry_at} = details || {};
      message = (
        <>
          You are about to lose access to {company_name} - {cert_number} on{' '}
          <strong>{dayjs(verification_expiry_at).format('YYYY-MM-DD')}</strong>
        </>
      );
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--warning';
      link = `/certifications-list`;
      break;
    }
    case 'verification_grace_period_nearing_expiration_today': {
      const {company_name, cert_number} = details || {};
      message = (
        <>
          You are about to lose access to {company_name} - {cert_number}{' '}
          <strong>today</strong>
        </>
      );
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      link = `/certifications-list`;
      break;
    }
    case 'verification_grace_period_expired': {
      const {company_name, cert_number} = details || {};
      message = `You lose access to ${company_name} - ${cert_number}`;
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      link = `/certifications-list`;
      break;
    }
    case 'watchlist_certifications_update': {
      const {number_of_updated_certs} = details || {};

      const label =
        number_of_updated_certs > 1
          ? 'Certifications have been updated'
          : 'Certification has been updated';

      message = `${number_of_updated_certs} ${label}`;
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--success';
      link = `/watch-list`;
      break;
    }
    case 'import_status_update': {
      const {status_code} = details || {};

      switch (status_code) {
        case ACTIVITY_RESULT_STATUS.success:
          message = 'Bulk Import Completed Successfully';
          icon = 'check-circle';
          iconColor = `notification__icon--success`;
          break;
        case ACTIVITY_RESULT_STATUS.error:
          message = 'Bulk Import Process Failed';
          icon = 'exclamation-triangle';
          iconColor = `notification__icon--error`;
          break;
        case ACTIVITY_RESULT_STATUS.partially_verified:
          message = 'Bulk Import Partially Successful';
          icon = 'exclamation-triangle';
          iconColor = `notification__icon--warning`;
          break;
        default:
          break;
      }

      link = `/import-management/activities`;
      break;
    }
    case 'batch_error': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data upload failed';
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    case 'batch_validated': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data has been validated';
      icon = 'check-square';
      iconColor = 'notification__icon--success';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    case 'batch_partially_imported': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data has been partially imported';
      icon = 'check-square';
      iconColor = 'notification__icon--warning';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    case 'batch_ftp_error': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data upload failed';
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    // BE should not send this code, this should be only for the old entries of this code that is unread
    case 'batch_ftp_validated': {
      const {dm_upload_batch_id} = details;

      message = (
        <>
          Import using FTP Server <b className="text-success">validated</b>
        </>
      );
      icon = 'check-square';
      iconColor = 'notification__icon--success';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    case 'batch_ftp_partially_imported': {
      const {dm_upload_batch_id} = details;

      message = 'Certification data has been partially imported';
      icon = 'check-square';
      iconColor = 'notification__icon--warning';
      link = `/import-management/activities/${dm_upload_batch_id}`;
      break;
    }
    // BE should not send this code, this should be only for the old entries of this code that is unread
    case 'file_upload_success':
      if (isEmpty(details) || !is_cert_upload) {
        message = (
          <>
            Certification Body Data has been{' '}
            <b className="text-primary">uploaded</b>
          </>
        );
        link = '/cb-upload/activity';
      } else {
        message = `${file_name} - ${uploaded_cert_count} certifications has been successfully uploaded`;
        link = null;
      }
      icon = 'check-square';
      iconColor = 'notification__icon--success';
      break;
    case 'file_upload_error':
      if (isEmpty(details) || !is_cert_upload) {
        message = 'Certification Body data upload failed';
        link = '/cb-upload/activity';
      } else {
        // BE should not send this scenario this should be only for the old entries of this code that is unread
        message = `${file_name} - Failed to upload all certifications. ${uploaded_cert_count} uploaded; ${skipped_cert_count} skipped with ${error_count} errors`;
        link = null;
      }
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      break;
    // BE should not send this code this should be only for the old entries of this code that is unread
    case 'ftp_file_upload_success':
      if (isEmpty(details) || !is_cert_upload) {
        message = (
          <>
            Certification Body Data has been{' '}
            <b className="text-primary">fetched</b>
          </>
        );
        link = '/cb-upload/activity';
      } else {
        message = `${file_name} - ${uploaded_cert_count} certifications has been successfully uploaded through FTP`;
        link = null;
      }
      icon = 'check-square';
      iconColor = 'notification__icon--success';
      break;
    case 'ftp_file_upload_error':
      if (isEmpty(details) || !is_cert_upload) {
        message = 'Certification Body data upload failed';
        link = '/cb-upload/activity';
      } else {
        // BE should not send this scenario this should be only for the old entries of this code that is unread
        message = `${file_name} - Failed to upload all certifications through FTP. ${uploaded_cert_count} uploaded; ${skipped_cert_count} skipped with ${error_count} errors`;
        link = null;
      }
      icon = 'exclamation-triangle';
      iconColor = 'notification__icon--error';
      break;
    case 'two_fa_enabled':
      icon = 'lock';
      iconColor = 'notification__icon--enabled';
      message = '2FA Authentication is enabled.';
      link = '/settings/security';
      break;
    case 'two_fa_disabled':
      icon = 'unlock';
      iconColor = 'notification__icon--disabled';
      message = '2FA Authentication is disabled.';
      link = '/settings/security';
      break;
    case 'update_password':
      icon = 'key';
      iconColor = 'notification__icon--password';
      message = 'Password has been updated.';
      link = '/settings/security';
      break;
    case 'certificate_update':
      icon = 'folder-open';
      iconColor = 'notification__icon--cert';
      message = `Certification has been updated`;
      link = '/certifications-list';
      break;
    case 'watchlist_certificate_update':
      icon = 'folder-open';
      iconColor = 'notification__icon--cert';
      message = 'Watched Certificate has been updated';
      link = '/watch-list';
      break;
    case 'company_updated':
      icon = 'building';
      iconColor = 'notification__icon--company';
      message = 'Update Company';
      link = '/';
      break;
    case 'enquiry':
      icon = 'envelope';
      iconColor = 'notification__icon--enquiry';
      message = 'New message on your enquiry';
      link = '/enquiries';
      break;
    case 'enquiry_reply':
      icon = 'envelope';
      iconColor = 'notification__icon--enquiry';
      message = 'New reply on your enquiry';
      link = '/enquiries';
      break;

    default:
      break;
  }

  return {
    message: message,
    link: link,
    iconColor: iconColor,
    icon: icon,
  };
}

function NotificationItem({notification, onClick, onMarkRead}) {
  const {code, status, created_at, details} = notification;
  const {message, link, iconColor, icon} = getNotificationProperties(
    code,
    details
  );

  const className = classNames(styles['notifications__item'], {
    [styles['notifications__item--read']]: status === 'read',
    [styles['notifications__item--unread']]: status === 'unread',
  });

  return (
    <div className={className}>
      <div className="my-4">
        <IconFA
          name={`${icon} fa-lg`}
          className={classNames(
            styles['notification__icon'],
            styles[iconColor]
          )}
        />
      </div>
      <div
        className={styles['notifications__item--content']}
        onClick={() => onClick(link)}
      >
        <Badge className={styles['notifications__item--content--badge']}>
          {NOTIFICATION_CATEGORY_LABEL[code]}
        </Badge>
        <div>
          <p className={styles['notifications__item--content--message']}>
            {message}
          </p>
        </div>
        <span
          className={classNames(
            'text-muted',
            styles['notifications__item--content--date']
          )}
        >
          <LabelDate timestamp={created_at} format="fromNow" />
        </span>
      </div>
      <div className={styles['notifications__item--actions']}>
        <NotificationDropItem
          notification={notification}
          onMarkRead={onMarkRead}
        />
      </div>
    </div>
  );
}

function PrivateNavNotification(props) {
  const {dark} = props;

  return (
    <NotificationProvider>
      <NotificationConsumer>
        {({
          isOpen,
          isFetching,
          isFetchingMore,
          data,
          count,
          nextKey,
          handleFetchNotifications,
          handleClick,
          handleToggle,
          handleMarkAsRead,
        }) => {
          return (
            <Dropdown isOpen={isOpen} toggle={handleToggle} nav>
              <DropdownToggle
                nav
                color="transparent"
                className="position-relative mr-3"
              >
                <IconFA
                  name="bell-o fa-lg"
                  className={dark ? 'text-light' : 'text-body'}
                />
                {count > 0 && (
                  <span className={styles['notifications__badge']}>
                    {count > 99 ? '99+' : count}
                  </span>
                )}
              </DropdownToggle>
              <DropdownMenu className={('p-0', styles['notifications__list'])}>
                <DropdownItem header className="p-0">
                  <ContentTitle
                    className="py-3"
                    title={
                      <span className="mb-0 h5 flex-grow-1">Notifications</span>
                    }
                  >
                    <NotificationDropAll toggle={handleToggle} />
                  </ContentTitle>
                </DropdownItem>

                {isFetching && (
                  <div className="d-flex align-items-center justify-content-center p-3 h-100">
                    <LoadingSpinner isLoading />
                  </div>
                )}

                {!isFetching && isEmpty(data) && (
                  <div className={styles['notifications__item--empty']}>
                    <div className="mb-1">
                      <IconFA name="inbox" /> No messages
                    </div>

                    <Link to="/settings/notification">
                      Your notification preferences..
                    </Link>
                  </div>
                )}

                <div style={{overflowY: 'auto', maxHeight: '300px'}}>
                  {!isFetching &&
                    !isEmpty(data) &&
                    data.map((item, index) => {
                      return (
                        <NotificationItem
                          key={index}
                          notification={item}
                          onClick={(link) =>
                            handleClick(item.status, item.notification_id, link)
                          }
                          onMarkRead={handleMarkAsRead}
                        />
                      );
                    })}
                </div>

                {!isFetching && nextKey && (
                  <Button
                    color="link"
                    onClick={handleFetchNotifications}
                    block
                    disabled={isFetchingMore}
                  >
                    Load more
                  </Button>
                )}
              </DropdownMenu>
            </Dropdown>
          );
        }}
      </NotificationConsumer>
    </NotificationProvider>
  );
}

export default PrivateNavNotification;
