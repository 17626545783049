import Badge from 'components/Badges/Badge';
import {IconFA} from 'components/Icons';
import {ImageAvatar} from 'components/Images';
import {
  ACCOUNT_ROLE_OPTIONS,
  ACCOUNT_ROLE_OPTIONS_COLOR,
  ACCOUNT_ROLES,
} from 'configs';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import {IAF_DC_URL} from 'configs/Environment';
import {
  SUBSCRIPTION_PLANS_TYPE,
  SUBSCRIPTION_PLANS_TYPE_COLOR,
} from 'configs/subscription';
import {COMPANY_TYPE} from 'configs/types';
import {useAuth} from 'contexts/AuthContext';
import {useSubscription} from 'contexts/SubscriptionContext';
import {get, startCase} from 'lodash';
import useManageSubscription from 'modules/membership/useManageSubscription';
import React from 'react';
import {Link} from 'react-router-dom';
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from 'reactstrap';
import urljoin from 'url-join';
function PrivateNavUser() {
  const {account, companyType, isDefault} = useAuth();

  const entityId = get(account, 'entity_id', '');
  const accountRole = get(account, 'account_role', '');
  const isSuperAdmin = accountRole === ACCOUNT_ROLES.super_admin;

  const {subscriptionPlan, isSubscribed} = useSubscription();
  const {onManage} = useManageSubscription();

  let liveProfile;
  let liveProfileTag;
  let liveProfileProps;

  let userTag;
  let userProps;

  let logoutTag;
  let logoutProps;

  switch (companyType) {
    default:
      liveProfile = null;
      liveProfileTag = null;
      liveProfileProps = {};

      userTag = Link;
      userProps = {
        to: '/settings/user-profile',
      };

      logoutTag = Link;
      logoutProps = {
        to: '/logout',
      };
      break;
    case COMPANY_TYPE.ab:
      liveProfile = urljoin(IAF_DC_URL, `/accreditation-body/${entityId}`);
      liveProfileTag = 'a';
      liveProfileProps = {
        href: liveProfile,
      };

      userTag = 'a';
      userProps = {
        href: urljoin(IAF_DC_URL, `/settings/user-profile`),
      };

      logoutTag = 'a';
      logoutProps = {
        href: urljoin(IAF_DC_URL, `/logout`),
      };
      break;
    case COMPANY_TYPE.cb:
      liveProfile = urljoin(IAF_DC_URL, `/certification-body/${entityId}`);
      liveProfileTag = 'a';
      liveProfileProps = {
        href: liveProfile,
      };

      userTag = 'a';
      userProps = {
        href: urljoin(IAF_DC_URL, `/settings/user-profile`),
      };

      logoutTag = 'a';
      logoutProps = {
        href: urljoin(IAF_DC_URL, `/logout`),
      };
      break;
    case COMPANY_TYPE.ce:
      liveProfile = `/certified-entity/${entityId}`;
      liveProfileTag = Link;
      liveProfileProps = {
        to: liveProfile,
      };

      userTag = 'a';
      userProps = {
        href: '/settings/user-profile',
      };

      logoutTag = 'a';
      logoutProps = {
        href: `/logout`,
      };
      break;
    case COMPANY_TYPE.mncb:
      userTag = 'a';
      userProps = {
        href: urljoin(IAF_DC_URL, `/settings/user-profile`),
      };

      logoutTag = 'a';
      logoutProps = {
        href: urljoin(IAF_DC_URL, `/logout`),
      };
      break;
  }

  const isSubscription =
    ENABLE_SUBSCRIPTION &&
    (companyType === COMPANY_TYPE.company || companyType === COMPANY_TYPE.ce);

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle nav className="p-0">
        <ImageAvatar
          image={account.account_image}
          name={account.account_first_name + ' ' + account.account_last_name}
          size="40"
        />
      </DropdownToggle>

      <DropdownMenu right>
        {isSubscription && (
          <DropdownItem>
            <Badge
              pill
              className="mr-1"
              color={SUBSCRIPTION_PLANS_TYPE_COLOR[subscriptionPlan || 'free']}
            >
              {startCase(SUBSCRIPTION_PLANS_TYPE[subscriptionPlan || 'free'])}{' '}
              Plan
            </Badge>
            <Badge color={ACCOUNT_ROLE_OPTIONS_COLOR[accountRole]} pill outline>
              {ACCOUNT_ROLE_OPTIONS[accountRole]}
            </Badge>
          </DropdownItem>
        )}

        <DropdownItem tag={Link} to="/dashboard">
          <IconFA name="dashboard" className="mr-1" />
          Dashboard
        </DropdownItem>

        <DropdownItem divider />

        {liveProfile && (
          <React.Fragment>
            <DropdownItem tag={liveProfileTag} {...liveProfileProps}>
              <IconFA name="bolt" className="mr-1" />
              Live Profile
            </DropdownItem>

            <DropdownItem divider />
          </React.Fragment>
        )}

        <DropdownItem header>Settings</DropdownItem>

        <DropdownItem tag={userTag} {...userProps}>
          <IconFA name="cog" className="mr-1" />
          User Settings
        </DropdownItem>

        {ENABLE_SUBSCRIPTION && isSuperAdmin && (
          <DropdownItem tag={'a'} href="/membership/overview">
            <IconFA name="dollar" className="mr-1" />
            Membership & billing
          </DropdownItem>
        )}

        {isSubscription && isSuperAdmin && isSubscribed && (
          <DropdownItem tag={Button} onClick={onManage}>
            <IconFA name="credit-card" className="mr-1" />
            Update Payment Details
          </DropdownItem>
        )}

        {(companyType === COMPANY_TYPE.ab ||
          companyType === COMPANY_TYPE.cb) && (
          <DropdownItem
            tag="a"
            href={
              isDefault
                ? urljoin(IAF_DC_URL, '/branches') //hq cb/ab account
                : urljoin(IAF_DC_URL, '/settings/account') //non-hq cb/ab account
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconFA name="building-o" className="mr-1" />
            Account Settings
          </DropdownItem>
        )}

        <DropdownItem divider />

        <DropdownItem tag={logoutTag} {...logoutProps} className="text-danger">
          <IconFA name="sign-out" className="mr-1" />
          Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default PrivateNavUser;
