import styles from './styles.module.scss';
import React from 'react';
import {Link} from 'react-router-dom';
import {LoadingPage} from 'components/Loading';

const Failed = (
  <div className={styles['loading-container']}>
    <h4 className="mb-3">Failed to load page</h4>
    <p className="mb-3 text-center">
      Error occurred during the loading of page.
      <br />
      Please retry to load this page. or restart from <Link to="/">home</Link>.
    </p>

    <div>
      <button
        title="Reload page"
        onClick={() => window.location.reload()}
        className="ant-btn ant-btn-link"
      >
        Reload
      </button>
    </div>
  </div>
);

function LoadingComponent(props) {
  const {error, timedOut, pastDelay, retry} = props;

  if (error || retry) {
    // When the loader has errored
    return <Failed />;
  } else if (timedOut) {
    // When the loader has taken longer than the timeout
    return <LoadingPage isLoading={true} />;
  } else if (pastDelay) {
    // When the loader has taken longer than the delay
    return <LoadingPage isLoading={true} />;
  } else {
    // When the loader has just started
    return <LoadingPage isLoading={true} />;
  }
}

export default LoadingComponent;
