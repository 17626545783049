import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {useViewport} from 'contexts/ViewportContext';

function usePrivateNavSearchModal() {
  const [isOpen, setIsOpen] = useState(false);
  const {pathname} = useLocation();
  const {size} = useViewport();

  const toggleOpen = () => setIsOpen(!isOpen);

  // close sidebar and backdrop when screen resizes or pathname changes
  useEffect(() => {
    setIsOpen(false);
  }, [size, pathname]);

  return {isOpen, toggleOpen};
}

export default usePrivateNavSearchModal;
