import React from 'react';
import {Waypoint} from 'react-waypoint';
import {LoadingSpinner} from 'components/Loading';

function Component({loadMore}) {
  function handleEnter({currentPosition, previousPosition}) {
    if (currentPosition === 'inside' && previousPosition === 'below') {
      return loadMore();
    }
  }

  return (
    <Waypoint onEnter={handleEnter}>
      <div className="text-center mt-5">
        <LoadingSpinner />
      </div>
    </Waypoint>
  );
}

export default Component;
